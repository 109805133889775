<template>
    <div id="guarantee-onboarding-start-page" class="vx-col sm:w-5/6 md:w-2/3 lg:w-2/3 xl:w-3/6 xxl:w-2/3 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <!-- <vs-progress :percent="40" :height="10" color="primary"></vs-progress> -->
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full p-0" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div class="p-10">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 center">
                                            <div class="vx-col w-full mb-12">
                                                <p class="text-2xl card-sub-title mb-12">Ayuda a {{ applicantName }} a completar su solicitud de crédito.</p>
                                                <p class="text-xl black">Te pediremos algunos datos para registrarte como obligado <br> solidario del crédito de Solicitante. </p>
                                            </div>
                                            <div class="vx-col w-full black mb-12">
                                                <div class="flex">
                                                    <a :href="$sanitizeUrl(guaranteeBlog)" target="_blank" rel="noopener noreferrer" class="black bold">
                                                        <feather-icon icon="InfoIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current cursor-pointer" class="mr-4" />
                                                    </a>
                                                    <span class="black">¿Tienes dudas? Aprende más sobre el <a :href="$sanitizeUrl(guaranteeBlog)" target="_blank" rel="noopener noreferrer" class="black bold"><u>obligado solidario</u></a> en nuestro blog. </span>
                                                </div>
                                            </div>
                                            <div class="vx-col w-full black">
                                                <div class="vx-row justify-between">
                                                    <div class="vx-col sm:w-full md:w-10/12">
                                                        <div class="w-full mb-6 text-justify flex">
                                                            <vs-checkbox v-model="cdcPayload.tyc" :vs-value="1">
                                                            </vs-checkbox>
                                                            <span class="ml-2 mt-2">Acepto el <a class="black bold" :href="$sanitizeUrl(privacyUrl)"
                                                                    target="_blank"><u>Aviso
                                                                    de
                                                                    Privacidad</u></a> y <a class="black bold" :href="$sanitizeUrl(termsUrl)"
                                                                    target="_blank"><u>Términos y
                                                                        Condiciones</u></a> y cláusulas de medios electrónicos tales como NIP.</span>
                                                        </div>
                                                    </div>
                                                    <div class="vx-col sm:w-full">
                                                        <vs-button @click.stop="doContinue()" class="ml-2" color="black">Continuar</vs-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>

export default {
    name: "GuaranteeOnboardingPrivacyTerms",
    props: ["guarantee"],
    data() {
        return {
            cdcPayload: {
                tyc: false
            },
            guaranteeBlog: "https://soporte.redgirasol.com/support/solutions/articles/48001256956--qu%C3%A9-es-un-obligado-solidario-",
            privacyUrl: "https://www.redgirasol.com/aviso-de-privacidad",
            termsUrl: "https://www.redgirasol.com/terminos-y-condiciones",
        }
    },
    async mounted() {
    },
    computed: {
        applicantName() {
            return this.guarantee.loan.applicant.name;
        }
    },
    methods: {
        async doContinue() {
            if (this.cdcPayload.tyc) {
                this.showLoading(true);
                const res = await this.publicApiPost(`api/guarantee/post/${this.guarantee.id}/contracts-agreement`, this.cdcPayload);
                this.showLoading(false);
                this.$emit("updated");
            } else {
                this.missingFieldsNotif(null, "Debes aceptar los términos y condiciones para continuar.");
            }
        }
    }
}
</script>

<style scoped>


</style>